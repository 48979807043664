@import '@/assets/css/index.scss';

.loadingLayer {
    display: block;
    mask-image: -webkit-radial-gradient(center, white, black);
    overflow: hidden;
    pointer-events: none;

    background-color: var(--color-white-016);

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        animation: 1s ease-in-out infinite 0.5s normal none running shimmer;
        will-change: transform;

        $waveColor: var(--color-black-005);

        background: linear-gradient(90deg, transparent, $waveColor, transparent);
    }
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(100%);
    }
}
